// Stores
import { useIsPreviewStore } from '@/stores/isPreview';

// Libraries
// import { Builder } from '@builder.io/sdk';

// Composables
import { getPageContent } from '~/composables/builder/api';
import { getComponents } from '@/composables/builder/blocks';
import { handleBuilderUpdates } from '@/composables/builder/updates';
import { debugBuilderBlocks } from '@/composables/builder/debugger';
import { updateSeo } from '@/composables/data/updateSeo';
import { handlePostMeta } from '@/composables/builder/postMeta';

const checkPageVariations = (page) => {
    const hasVariations = page && page.content && page.content.variations && Object.keys(page.content.variations).length > 0;
    const hasWinner = page.content?.meta?.winningTest && JSON.stringify(page?.content?.meta?.winningTest) !== '{}';
    return hasVariations && !hasWinner;
};

const fetchPage = async (route, page) => {
    const data = await getPageContent(route.fullPath);
    console.log(`🍤 ~ fetchPage ~ route.fullPath:`, route.fullPath);

    const breadcrumbs = await $fetch(`/api/webhooks/breadcrumbs?slug=${route.path}`);

    page = data;
    page.breadcrumbs = breadcrumbs;
    page.hasVariation = checkPageVariations(page);

    return page;
};

const getPage = async (debug = false, symbol = false) => {
    // Instantiate Stores
    const isPreviewStore = useIsPreviewStore();
    const isPreviewing = useRoute().fullPath.includes('builder.space=');

    // Variables
    const route = useRoute();
    const page = useState('page', () => null);

    let pageFound = false;

    if (process.server) {
        const event = useRequestEvent();
        if (event.context.builderData) {
            pageFound = true;
            page.value = event.context.builderData;
            page.value.breadcrumbs = event.context.breadcrumbs;
        } else {
            const data = await fetchPage(route, page.value);
            if (data.content) {
                page.value = data;
                pageFound = true;
                try {
                    updateSeo(page.value);
                } catch (error) {
                    console.error('Error updating SEO:', error);
                }
            }
        }
    } else {
        const data = await fetchPage(route, page.value, isPreviewing);
        if (data.content) {
            pageFound = true;
            useNuxtApp().hook('page:finish', () => {
                page.value = data;
               if (page && page.value && page.value.content) {
                    setTimeout(() => { 
                        try {
                            updateSeo(page.value);
                        } catch (error) {
                            console.error('Error updating SEO:', error);
                        }
                    }, 250);
                }
            });
            try {
                if (debug) debugBuilderBlocks(page.value.content);
            } catch (error) {
                console.error('Error debugBuilderBlocks:', error);
            }
        }
    }

    if (pageFound === false) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found: API returned no results.',
            fatal: true,
        });

        return;
    }

    if (page.value?.model == 'team-members' && page.value?.content?.data?.noPage == true) {
        navigateTo('/about/our-team/leadership-team');
    }

    try {
        updateSeo(page.value);
    } catch (error) {
        console.error('Error updating SEO:', error);
    }

    try {
        isPreviewStore.updateIsPreviewing(isPreviewing);
    } catch (error) {
        console.error('Error isPreviewStore.updateIsPreviewing:', error);
    }
    
    // Set the variation property if the page has variations and no winner
    try {
        page.value.hasVariation = checkPageVariations(page.value);
    } catch (error) {
        console.error('Error page.value.hasVariation:', error);
    }

    return page;
};

export { getPage, getPageContent, getComponents, debugBuilderBlocks, handleBuilderUpdates, handlePostMeta };
